<template>
    <div class="mainWrapper pb-0 introducingZuluTrade">
        <section class="blockElement space bg-white">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-12">
                        <h1 class="mb-2 f-62" v-html="$t('introducing_zuluTrade.introducing1')"></h1>
                        <p class="f-24 my-4"><span>{{$t('introducing_zuluTrade.introducing2')}}</span><br>
                            <span>{{$t('introducing_zuluTrade.introducing3')}}</span></p>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="bold button fillBtn zulu_btn px-md-5 mt-3">{{$t('introducing_zuluTrade.introducing4')}} </router-link>
                        <a v-else class="bold button fillBtn zulu_btn px-md-5 mt-3" :href="static_vars.domainURL+'register/'">{{$t('introducing_zuluTrade.introducing5')}} </a>
                        <img class="mt-4 light" src="/assets/images/newBannerMob.png" :alt="$t('alttag.alt1')" :title="$t('alttag.title1')" />
                        <img class="mt-4 dark d-none" src="/assets/images/newBannerMob-dark.png" :alt="$t('alttag.alt1')" :title="$t('alttag.title1')"/>
                    </div>
                    <div class="col-12 col-md-8 content">
                        <p class="f-30 lgContent">{{$t('introducing_zuluTrade.introducing6')}} </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12 col-md-11">
                        <h2 class="mb-3 mb-md-5">{{$t('introducing_zuluTrade.introducing7')}} </h2>
                        <div class="midContent">
                            <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing8')}} </h3>
                            <p>{{$t('introducing_zuluTrade.introducing9')}} </p>
                        </div>
                        <v-lazy-image width="1170" height="610" class="mt-4" src="/assets/images/subscriptionPlan.png" :alt="$t('alttag.alt2')" :title="$t('alttag.title2')" />
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12 col-md-11">
                        <div class="midContent">
                            <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing10')}} </h3>
                            <p>{{$t('introducing_zuluTrade.introducing11')}} </p>
                        </div>
                        <img width="1170" height="610" class="mt-4" src="/assets/images/newFeatures.png" :alt="$t('alttag.alt3')" :title="$t('alttag.title3')" />
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12 col-md-11">
                        <div class="midContent">
                            <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing12')}} </h3>
                            <p>{{$t('introducing_zuluTrade.introducing13')}}</p>
                        </div>
                        <v-lazy-image width="1170" height="610" class="mt-4" src="/assets/images/integratedBroker.png" :alt="$t('alttag.alt4')" :title="$t('alttag.title4')" />
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row midContent1170 columnBG p-md-3 align-items-center">
                    <div class="col-12 col-md-6">
                        <v-lazy-image width="556"  height="400" src="/assets/images/zulu-Group.png" :alt="$t('alttag.alt5')" :title="$t('alttag.title5')" />
                    </div>
                    <div class="col-12 col-md-6">
                        <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing14')}}</h3>
                        <p>{{$t('introducing_zuluTrade.introducing15')}} </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row midContent1170 columnBG p-md-3 align-items-center flex-row-reverse">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <v-lazy-image width="556" height="400" src="/assets/images/zuluGroup2.png" :alt="$t('alttag.alt6')" :title="$t('alttag.title6')" />
                    </div>
                    <div class="col-12 col-md-6">
                        <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing16')}} </h3>
                        <p>{{$t('introducing_zuluTrade.introducing17')}} </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-11">
                        <div class="row m-md-0">
                            <div class="col-12 col-md-6 mb-4 mb-md-0">
                                <div class="card-body">
                                    <v-lazy-image height="340" class="mb-3 w-100" src="/assets/images/zuluGroup3.png" :alt="$t('alttag.alt7')" :title="$t('alttag.title7')" />
                                    <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing18')}}</h3>
                                    <p>{{$t('introducing_zuluTrade.introducing19')}} </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card-body">
                                    <v-lazy-image height="340" class="mb-3 w-100" src="/assets/images/zuluGroup4.png" :alt="$t('alttag.alt8')" :title="$t('alttag.title8')" />
                                    <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing20')}} </h3>
                                    <p>{{$t('introducing_zuluTrade.introducing21')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-11">
                        <div class="row m-md-0">
                            <div class="col-12 col-md-6">
                                <div class="card-body">
                                    <v-lazy-image height="340" class="mb-3 w-100" src="/assets/images/zuluGroup6.png" :alt="$t('alttag.alt9')" :title="$t('alttag.title9')"/>
                                    <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing22')}}  </h3>
                                    <p v-html="$t('introducing_zuluTrade.introducing23')"></p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card-body">
                                    <v-lazy-image height="340" class="mb-3 w-100" src="/assets/images/zuluGroup5.png" :alt="$t('alttag.alt10')" :title="$t('alttag.title10')" />
                                    <h3 class="f-24 medium">{{$t('introducing_zuluTrade.introducing24')}}</h3>
                                    <p>{{$t('introducing_zuluTrade.introducing25')}} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
}
</script>